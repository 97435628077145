<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <invoice-view />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import InvoiceView from "./components/InvoiceView";
  
  export default {
    name: "Invoice",
    components: {
       InvoiceView
    },
    mounted(){
    var token = sessionStorage.getItem("token")
    if(token!="MyNameIsMaxi")
    this.$router.push({
                name: 'Sign In'
            })
  }
  };
  </script>
  
<template>
    <div class="card mb-4">
      <div class="card-body px-0 pt-0 pb-2">
        <div id="basic-info" class="card mt-2">
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-4">
                <label class="form-label">Invoice Number</label>
                <div
                  class="
                    d-sm-flex
                    align-items-center
                    bg-gray-100
                    border-radius-lg
                  "
                >
                  <p class="text-sm font-weight-bold my-auto ps-sm-2">
                    ENK-IV-&nbsp;
                  </p>
                  <div class="form-group my-auto w-60">
                    <div class="">
                      <!----><input
                        id=""
                        v-model="refNo"
                        type="text"
                        class="form-control form-control-default"
                        name=""
                        placeholder="001"
                        isrequired="false"
                      /><!---->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="horizontal dark">
            <div class="row">
              <div class="col-4">
                <label class="form-label">Customer Name</label>
                <div class="form-group">
                  <div class="">
                    <!----><input
                      id="firstName"
                      type="text"
                      v-model="CustomerInfo.CompanyName"
                      class="form-control form-control-default"
                      name=""
                      placeholder="first Name"
                      isrequired="false"
                    /><!---->
                  </div>
                </div>
              </div>
              <div class="col-4">
                <label class="form-label">Phone Number</label>
                <div class="form-group">
                  <div class="">
                    <!----><input
                      id="lastName"
                      type="number"
                      v-model="CustomerInfo.Phone"
                      class="form-control form-control-default"
                      name=""
                      placeholder="1234567890"
                      isrequired="false"
                    /><!---->
                  </div>
                </div>
              </div>
              <div class="col-4">
                <label class="form-label mt-2">Email</label>
                <div class="form-group">
                  <div class="">
                    <input
                      id="email"
                      v-model="CustomerInfo.EMail"
                      type="email"
                      class="form-control form-control-default"
                      name=""
                      placeholder="example@email.com"
                      isrequired="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label class="form-label mt-2">Address 1</label>
                <div class="form-group">
                  <div class="">
                    <input
                      id="confirmEmail"
                      v-model="CustomerInfo.Address1"
                      type="email"
                      class="form-control form-control-default"
                      name=""
                      placeholder="Address"
                      isrequired="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-3">
                <label class="form-label mt-2">Address 2</label>
                <div class="form-group">
                  <div class="">
                    <input
                      id="location"
                      v-model="CustomerInfo.Address2"
                      type="text"
                      class="form-control form-control-default"
                      name=""
                      placeholder="Address 2"
                      isrequired="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-3">
                <label class="form-label mt-2">City / State</label>
                <div class="form-group">
                  <div class="">
                    <input
                      id="confirmEmail"
                      v-model="CustomerInfo.City_State"
                      type="email"
                      class="form-control form-control-default"
                      name=""
                      placeholder="City"
                      isrequired="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-3">
                <label class="form-label mt-2">Pincode</label>
                <div class="form-group">
                  <div class="">
                    <input
                      id="location"
                      type="number"
                      v-model="CustomerInfo.PinCode"
                      class="form-control form-control-default"
                      name=""
                      placeholder="Pincode"
                      isrequired="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr class="horizontal dark">
            <div class="card-header pb-0">
              <h6>Service Details</h6>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="form-label mt-2">Service Details</label>
                <div class="form-group">
                  <div class="">
                    <!----><input
                      id="location"
                      type="text"
                      class="form-control form-control-default"
                      name=""
                      v-model="item.serviceDetails"
                      placeholder="Details"
                      isrequired="false"
                    /><!---->
                  </div>
                </div>
              </div>
              <div class="col-2">
                <label class="form-label mt-2">Quantity</label>
                <div class="form-group">
                  <div class="">
                    <!----><input
                      id="qty"
                      type="number"
                      v-model="item.Qty"
                      class="form-control form-control-default"
                      name=""
                      placeholder="Quantity"
                      isrequired="false"
                    /><!---->
                  </div>
                </div>
              </div>
              <div class="col-2">
                <label class="form-label mt-2">Charges</label>
                <div class="form-group">
                  <div class="">
                    <!----><input
                      id="charges"
                      type="number"
                      class="form-control form-control-default"
                      v-model="item.Charges"
                      name=""
                      placeholder="₹ 0.00"
                      isrequired="false"
                    /><!---->
                  </div>
                </div>
              </div>
              <div class="col-2">
                <label class="form-label mt-2">&nbsp;</label>
                <div class="">
                  <button
                    class="btn mb-0 bg-gradient-success btn-sm null null mb-0"
                    @click="AddtoList"
                  >
                    + Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="
                  text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                S.No
              </th>
              <th
                class="
                  text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                Service Details
              </th>
  
              <th
                class="
                  text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-2
                "
              >
                Quantity
              </th>
              <th
                class="
                  text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-2
                "
              >
                Charges
              </th>
              <th
                class="
                  text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-1
                "
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(Detailitem, i) in items" :key="i">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs">{{ i + 1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ Detailitem.serviceDetails }}
                </p>
              </td>
  
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">{{
                    Detailitem.Qty
                  }}</span>
                </div>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold"
                    >₹ {{ Detailitem.Charges.toFixed(2) }}</span
                  >
                </div>
              </td>
              <td>
                <a
                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                  href="javascript:;"
                  @click="removeList(i)"
                >
                  <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                </a>
              </td>
            </tr>
  
            <tr v-if="items.length > 0">
              <td colspan="2">
                <div class="d-flex px-2">
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm"></h6>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-xxl font-weight-bold">Total</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold"
                    >₹ {{ TotalValueOfInvoice.toFixed(2) }}</span
                  >
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      @click="clearData"
      class="
        btn
        mb-0
        bg-gradient-danger
        btn-sm
        null null
        float-end
        mr-1
        mt-3
        mb-3
      "
    >
      Clear
    </button>
    <button
      @click="generatePDF"
      class="btn mb-0 bg-gradient-info btn-sm null null float-end mr-1 mt-3 mb-3"
    >
      Print Invoice
    </button>
    <!-- <button  @click='clearData' class="btn mb-0 bg-gradient-info btn-sm null null float-end  mt-3 mb-3">Print Invoice</button> -->
  </template>
  
  <script>
  // https://www.npmjs.com/package/@meforma/vue-toaster
  import { createToaster } from "@meforma/vue-toaster";
  import { jsPDF } from "jspdf";
  import "jspdf-autotable";
  
  export default {
    name: "invoice-view",
    data() {
      return {
        toaster: createToaster({
          /* options */
        }),
        heading: "Invoice",
        refNo:0,
        MyAddress: {
          CompanyName: "Enkay Smart Solutions",
          Address1: "198, 2nd cross street,",
          Address2: "shanthi nagar, chromepet",
          Phone: "9715463805",
          EMail: "enkaysmartsolutions@gmail.com",
        },
        logo: require("@/assets/img/small-logos/logo.png"),
        CustomerInfo: {
          CompanyName: "",
          InvoiceNo: 0,
          Address1: "",
          Address2: "",
          City_State: "",
          PinCode: "",
          Phone: "",
          EMail: "",
        },
        TotalValueOfInvoice: 0,
        InvoiceDetails: {
          InvoiceDate: null,
          InvoiceEndDate: null,
        },
  
        moreText: [
          "Terms and conditions apply**",
          "If you have any questions, please contact Enkay Smart Solutions , 9715463805 , enkaysmartsolutions@gmail.com",
        ],
        item: { serviceDetails: null, Qty: null, Charges: null },
        items: [],
        // items: [
        //   { title: "Item 1", body: "I am item 1 body text" },
        //   { title: "Item 2", body: "I am item 2 body text" },
        //   { title: "Item 3", body: "I am item 3 body text" },
        //   { title: "Item 4", body: "I am item 4 body text" }
        // ]
      };
    },
    components: {},
    mounted() {
      var d = new Date();
  
      this.InvoiceDetails.InvoiceDate = d.toDateString();
      d.setDate(d.getDate() + 20);
      this.InvoiceDetails.InvoiceEndDate = d.toDateString();
    },
    methods: {
      clearData() {
        this.CustomerInfo = {
          CompanyName: "",
          Address1: "",
          Address2: "",
          City_State: "",
          PinCode: "",
          Phone: "",
          EMail: "",
        };
        this.items = [];
        this.TotalValueOfInvoice = 0;
      },
  
      TotalValue() {
        let m;
        this.TotalValueOfQuote =0;
        for (m = 0; m < this.items.length; m++) {
          this.TotalValueOfInvoice += this.items[m].Charges * 1;
        }
      },
      removeList(i) {
        this.toaster.error(
          this.items[i].serviceDetails + " -  Service Removed.",
          {
            // override the global option
            position: "top-right",
          }
        );
        this.items.splice(i, 1);
        this.TotalValue();
      },
      AddtoList() {
        if (
          this.item.serviceDetails != null &&
          this.item.serviceDetails != "" &&
          this.item.Qty != null &&
          this.item.Qty != "" &&
          this.item.Charges != null &&
          this.item.Charges != ""
        ) {
          var list = JSON.parse(JSON.stringify(this.item));
          this.items.push(list);
          this.item.serviceDetails = null;
          this.item.Qty = null;
          this.item.Charges = null;
          this.TotalValue();
          this.toaster.success("Added.", {
            // override the global option
            position: "top-right",
          });
        } else {
          this.toaster.error("Please enter all details.", {
            // override the global option
            position: "top-right",
          });
        }
      },
  
      addWaterMark(doc) {
  var totalPages = doc.internal.getNumberOfPages();

  for (var i = 1; i <= totalPages; i++) {
    var imgLogo = new Image();
    imgLogo.src = this.logo;
    doc.setPage(i)
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({opacity: 0.1}));
    doc.addImage(imgLogo, 'PNG', 1.5, 4, 5, 5);
    doc.restoreGraphicsState();
  }

  return doc;
},

      generatePDF() {
        let m;
        this.TotalValueOfInvoice = 0;
        for (m = 0; m < this.items.length; m++) {
          this.TotalValueOfInvoice += this.items[m].Charges * 1;
        }
  
        const columns = [
          { header: "No", dataKey: "Index" },
          { title: "Service Details", dataKey: "serviceDetails" },
          { title: "Quantity", dataKey: "Qty" },
          { title: "Charges (INR)", dataKey: "Charges" },
        ];
        var doc = new jsPDF({
          orientation: "portrait",
          unit: "in",
          format: "letter",
        });
  
        var imgLogo = new Image();
        imgLogo.src = this.logo;
        doc.addImage(imgLogo, "PNG", 0.4, 0.5, 0.8, 0.8);
  
        // text is placed using x, y coordinates
        doc.setFontSize(16).text(this.heading, 6.5, 1.5);
        doc.setFontSize(16).text(this.MyAddress.CompanyName, 0.5, 1.5);
        doc
          .setFontSize(10)
          .text("Invoice #  : ENK-IV-" + this.refNo, 6.3, 1.8);
        doc
          .setFontSize(10)
          .text("DATE       : " + this.InvoiceDetails.InvoiceDate, 6.3, 2.0);
  
        // doc.setFontSize(16).text(this.heading, 6.5, 1.0);
        doc.setFontSize(10).text(this.MyAddress.Address1, 0.5, 1.8);
        doc.setFontSize(10).text(this.MyAddress.Address2, 0.5, 2.0);
        doc.setFontSize(10).text(this.MyAddress.Phone, 0.5, 2.2);
        doc.setFontSize(10).text(this.MyAddress.EMail, 0.5, 2.4);
  
        doc.setFontSize(16).text("Customer Info", 0.5, 3.2);
        doc.setLineWidth(0.01).line(0.5, 3.3, 3.0, 3.3);
        doc.setFontSize(12).text(this.CustomerInfo.CompanyName, 0.5, 3.5);
        doc.setFontSize(10).text(this.CustomerInfo.Address1, 0.5, 3.7);
        doc.setFontSize(10).text(this.CustomerInfo.Address2, 0.5, 3.9);
        doc
          .setFontSize(10)
          .text(
            this.CustomerInfo.City_State + " " + this.CustomerInfo.PinCode,
            0.5,
            4.1
          );
        doc
          .setFontSize(10)
          .text(
            this.CustomerInfo.Phone + ", " + this.CustomerInfo.EMail,
            0.5,
            4.3
          );
  
        // create a line under heading
        doc.setLineWidth(0.01).line(0.5, 1.6, 8.0, 1.6);
        // Using autoTable plugin
        doc.autoTable({
          columns,
          body: this.items.map((d, i) => ({ ...d, Index: i + 1 })),
          margin: { left: 0.5, top: 5.25 },
          foot: [["", "", "Total", this.TotalValueOfInvoice]],
          didParseCell: (data) => {
            if (data.cell && data.cell.section === "head") {
              switch (data.cell.raw) {
                case "Charges (INR)":
                  data.cell.styles.halign = "right";
                  break;
                case "Quantity":
                  data.cell.styles.halign = "right";
                  break;
                default:
                  data.cell.styles.halign = "left";
                  break;
              }
            }
            if (data.cell && data.cell.section === "foot") {
              data.cell.styles.halign = "right";
            }
            if (data.cell) {
              console.log(data);
            }
          },
          columnStyles: {
            2: { halign: "right" },
            3: { halign: "right" },
          },
          headStyles: {
            fillColor: [217, 217, 214],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
          footStyles: {
            fillColor: [217, 217, 214],
            textColor: [0, 0, 0],
            fontSize: 12,
          },
        });
        // Using array of sentences
        // doc
        //   .setFont("helvetica")
        //   .setFontSize(8)
        //   .text(this.moreText, 0.5, 4.3, { align: "left"});
  
        // Creating footer and saving file
        doc = this.addWaterMark(doc);
        doc
          .setFont("times")
          .setFontSize(11)
          // .setFontStyle("italic")
          .setTextColor(36, 36, 36)
          .text(this.moreText, 0.5, doc.internal.pageSize.height - 1.5)
          .save(
            `ENK-IV-${this.refNo}.pdf`
          );
      },
    },
  };
  </script>
  
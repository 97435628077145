<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <gst-invoice-view />
      </div>
    </div>
  </div>
</template>

<script>
import GstInvoiceView from "./components/GstInvoiceView";

export default {
  name: "Invoice",
  components: {
    GstInvoiceView
  },
  mounted(){
    var token = sessionStorage.getItem("token")
    if(token!="MyNameIsMaxi")
    this.$router.push({
                name: 'Sign In'
            })
  }
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <quote-view />
      </div>
    </div>
  </div>
</template>

<script>
import QuoteView from "./components/QuoteView";

export default {
  name: "Quote",
  components: {
    QuoteView
  },
  mounted(){
    var token = sessionStorage.getItem("token")
    if(token!="MyNameIsMaxi")
    this.$router.push({
                name: 'Sign In'
            })
  }
};
</script>
